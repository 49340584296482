'use client'

import React from 'react'
import {css} from '@emotion/react'
import {useTranslations} from 'next-intl'

import Button from '../../../../components/elements/Button'
import {useRouter} from '../../../../app/i18n/routing'

export default function NotFound() {
  const styles = {
    root: css`
    `,

    container: css`
      max-width: 800px;
    `,

    title: css`
      font-size: 2.5em;
      font-weight: 600;
      margin: unset;
    `,

    description: css`
      font-size: 1.2em;
    `,

    button: css`
      margin-top: 16px;
      font-size: 1.2em;
    `,
  }

  const t = useTranslations()

  const router = useRouter()
  const backToHome = () => {
    router.push('/')
  }

  return (
    <div css={styles.root}>
      <div css={styles.container}>
        <h1 css={styles.title}>{t('not-found.title')}</h1>
        <div css={styles.description}>{t('not-found.description')}</div>
        <Button
          size="small"
          css={styles.button}
          onClick={backToHome}
        >{t('not-found.back-home-button')}</Button>
      </div>
    </div>
  )
}

